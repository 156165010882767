import React from "react";
import Hero from "../Hero/Hero";

const HeroProductSingle = ({logo, smallLogo, image, mobileImage, productName, title, primaryCta, secondaryCta, poweredByImage, operatingSystemLogos, operatingSystemsLabel, altImageLayout, direction, theme, productColor}) => {
    return (
        <Hero
            logo={logo}
            smallLogo={smallLogo}
            image={image}
            mobileImage={mobileImage}
            title={title}
            productName={productName}
            primaryCta={primaryCta}
            secondaryCta={secondaryCta}
            poweredByImage={poweredByImage}
            operatingSystemLogos={operatingSystemLogos}
            operatingSystemsLabel={operatingSystemsLabel}
            altImageLayout={altImageLayout}
            direction={direction}
            type="product"
            useBgPattern={true}
            theme={theme}
            productColor={productColor}
        />
    )
};

export default HeroProductSingle;