import React from "react";
import styles from "./ComplexList.module.scss";
import GatsbyImage from "gatsby-image";
import generateFluid from "../../util/generateFluid";
import Button from "../elements/Button";
import FadeUp from "../effects/FadeUp";
import Section from "../layout/Section";
import Container from "../layout/Container";
import Row from "../layout/Row";

const ComplexList = ({listItems, afterExtended, reducedSpacingBottom, theme}) => {
    const list = listItems && listItems.length ? listItems.map((item,i) => {
        return (
            <FadeUp
                stayOpaque={afterExtended}
                threshold={0}
                className={styles.complexList__items__item}
                key={i}
            >
                <Row verticalCenter={true} noWrapDesktop={true} className={styles.complexList__items__item__row}>
                    <div className={`${styles.complexList__items__item__content} ${item.image ? styles.complexList__items__item__contentMargin : ""}`}>
                        {item.icon && item.icon.length > 0 && <span dangerouslySetInnerHTML={{__html: item.icon[0].contents}} className={styles.complexList__items__item__icon} />}
                        {item.title && <h4>{item.title}</h4>}
                        <div className={item.link && item.link.url ? styles.spacingBottom : ""}>
                            <span
                                dangerouslySetInnerHTML={{__html: item.content}}
                                className={styles.complexList__items__item__content__text}
                            />
                        </div>
                        {item.link && item.link.url && (
                            <Button
                                text={item.link.text}
                                url={item.link.url}
                                external={item.link.target === "_blank"}
                                target={item.link.target}
                                simpleStyle={item.linkStyle === "button" ? false : true}
                                altColors={item.linkStyle === "button"}
                                backgroundFilled={item.linkStyle === "button"}
                            />
                        )}
                    </div>
                    <div className={styles.complexList__items__item__image}>
                        {item.image && <GatsbyImage fluid={generateFluid(item.image, "(min-width: 1680px) 455px, (min-width: 600px) 27vw, 100vw")} />}
                    </div>
                </Row>
            </FadeUp>
        );
    }) : null;

    return listItems && listItems.length ? (
        <Section className={`${styles.complexList} ${afterExtended ? styles.complexListAfterExtended : ""}`} reducedSpacingBottom={reducedSpacingBottom} theme={theme}>
            <Container>
                <Row>
                    <div className={styles.complexList__items}>
                        {list}
                    </div>
                </Row>
            </Container>
        </Section>
    ) : null;
};

export default ComplexList;