import React, {useState, useEffect, useRef} from "react";
import 'intersection-observer';
import { InView } from 'react-intersection-observer';
import styles from "./StatisticsItem.module.scss";

const StatisticsItem = ({item}) => {
    const [number, setNumber] = useState(0);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [numberEndVal, setNumberEndVal] = useState(0);
    const [containsSingleNumber, setContainsSingleNumber] = useState(false);

    const regexNums = useRef(/\d+/g);

    useEffect(() => {
        const numbersInString = isNaN(item.number) ? item.number.match(regexNums.current) : [item.number];
        setNumberEndVal(numbersInString[0]);
        setContainsSingleNumber(numbersInString.length <= 1);
    },[item.number]);

    const animateNumber = (startVal, setNum, endVal, duration) => {
        let startTime = null;

        const step = (time) => {
            if (!startTime) startTime = time;
            const progress = Math.min((time - startTime) / duration, 1);
            const easedProgress = Math.min(easeOutQuad(progress), 1);
            setNum(Math.floor(easedProgress * (endVal - startVal) + startVal));
            if (easedProgress < 1) {
                window.requestAnimationFrame(step);
            }
        };

        window.requestAnimationFrame(step);
    };

    const easeOutQuad = function(t, b = 0, _c = 1, d = 1) {
        let c = _c - b;
        return -c * (t /= d) * (t - 2) + b;
    };

    return (
        <li>
            <div className={styles.item}>
                <InView
                    threshold={1.0}
                    as="div"
                    onChange={inView => {
                        if(inView && !hasAnimated){
                            const startVal = numberEndVal - 50 < 0 ? 0 : numberEndVal - 50;
                            if(containsSingleNumber) animateNumber(startVal, setNumber, numberEndVal, 1500);
                            setHasAnimated(true);
                        }
                    }}
                >
                    <div className={styles.item__value}>
                        {numberEndVal ? <p className="stats">{containsSingleNumber ? item.number.replace(regexNums.current, number) : item.number}<span className="meta">{item.suffix}</span></p> : null}
                    </div>
                    {item.description ? <p>{item.description}</p> : null}
                </InView>
            </div>
        </li>
    );
};

export default StatisticsItem;