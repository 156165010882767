import React from "react";
import SEO from "../components/seo";
import HeroProductSingle from "../components/HeroProductSingle";
import { graphql } from "gatsby"
import Introduction from "../components/Introduction";
import Cta from "../components/Cta";
import IntroductionAlt from "../components/IntroductionAlt";
import Solutions from "../components/Solutions";
import Resources from "../components/Resources";
import ComplexList from "../components/ComplexList";
import InformationStrip from "../components/InformationStrip";
import Statistics from "../components/Statistics";
import Footer from "../components/Footer";
import Background from "../components/layout/Background";

const productSinglePage = (props) => {
    const craftData = props.data.craft.entry;

    const {title, productSingleHero, informationStrip, introductionWithBg, productSingleStatistics, productFeatures, solutions, resources, callToAction} = craftData;

    const heroData = productSingleHero ? productSingleHero[0] : {};
    const {primaryCta, secondaryCta, logo, smallLogo, image, mobileImage, productName, displayTitle, imageNudgedPartiallyOffScreen, poweredByLogo, operatingSystemLogos, operatingSystemsLabel, direction, theme} = heroData;

    const productColor = theme && theme[0] ? theme[0].color : null;
    const pageTheme = productColor ? "custom" : "brightscan";

    const introductionData = introductionWithBg ? introductionWithBg[0] : {};
    const {introductionTitle, background: introductionImage} = introductionData;
    const introductionContent = introductionData && introductionData.introductionContent && `<p>${introductionData.introductionContent}</p>`;

    const statisticsData = productSingleStatistics ? productSingleStatistics[0] : {};
    const {statsTitle, statsContent, statistics: statsItems} = statisticsData;

    const complexListItems = productFeatures.map(item => {
        return {
            icon: item.icon,
            title: item.listTitle,
            content: item.listContent,
            image: item.image ? item.image[0] : null,
            linkStyle: item.linkStyle,
            link: item.listLink
        }
    });

    const solutionsData = solutions && solutions.length ? solutions[0] : {};
    const {solutionsTitle, solutions: solutionsItems} = solutionsData;
    const solutionsContent = solutionsData && solutionsData.solutionsContent && `<p>${solutionsData.solutionsContent}</p>`;    

    const resourcesData = resources && resources.length ? resources[0] : {};
    const {resourcesTitle, resourcesContent, resourcesLink, otherLinks: resourcesOtherLinks} = resourcesData;

    const ctaData = callToAction && callToAction.length ? callToAction[0] : {};
    const {ctaTitle: ctaText, primaryLink: ctaLink1, secondaryLink: ctaLink2} = ctaData;

    return (
        <Background theme="light">
            <SEO title={title || ""} seomatic={craftData.seomatic} />
            <HeroProductSingle
                logo={logo && logo.length ? logo : null}
                smallLogo={smallLogo && smallLogo.length ? smallLogo : null}
                image={image && image.length ? image : null}
                mobileImage={mobileImage && mobileImage.length ? mobileImage : null}
                title={displayTitle || null}
                productName={productName || null}
                primaryCta={primaryCta || null}
                secondaryCta={secondaryCta || null}
                poweredByImage={poweredByLogo && poweredByLogo.length ? poweredByLogo : null}
                operatingSystemLogos={operatingSystemLogos || null}
                operatingSystemsLabel={operatingSystemsLabel || null}
                altImageLayout={imageNudgedPartiallyOffScreen ? imageNudgedPartiallyOffScreen : false}
                direction={direction || null}
                productColor={productColor}
                theme={`${pageTheme}-dark`}
            />
            <InformationStrip
                items={informationStrip}
                altColors={true}
                productColor={productColor}
                theme={`${pageTheme}-light`}
            />
            <Introduction
                title={introductionTitle}
                content={introductionContent}
                image={introductionImage}
                largeSpacing={true}
                productColor={productColor}
                theme={`${pageTheme}-image`}
            />
            <IntroductionAlt
                title={statsTitle}
                content={statsContent}
                shortPaddingBottom={statsItems && statsItems.length > 0}
                altColors={true}
                productColor={productColor}
                theme={`${pageTheme}-light`}
            />
            <Statistics
                items={statsItems}
                extended={true}
                productColor={productColor}
                theme={`${pageTheme}-light`}
            />
            <ComplexList
                listItems={complexListItems}
                afterExtended={true}
                theme="light"
            />
            <Introduction
                title={solutionsTitle}
                content={solutionsContent}
                colorsAlt={true}
                shortPaddingBottom={solutionsData}
                theme="light"
            />
            <Solutions
                items={solutionsItems}
                theme="light"
            />
            <Resources
                title={resourcesTitle || null}
                content={resourcesContent || null}
                link={resourcesLink || null}
                otherLinks={resourcesOtherLinks || null}
                theme="light"
            />
            <Cta text={ctaText} link1={ctaLink1} link2={ctaLink2} theme="brightscan-dark" />
            <Footer
                theme="dark"
            />
        </Background>
    );
}


export const pageQuery = graphql`
    query productSinglePage($id: CRAFT_QueryArgument!) {
        craft {
            entry(id:[$id]) {
                title
                seomatic(asArray:true) {
                    metaTitleContainer
                    metaTagContainer
                    metaLinkContainer
                    metaScriptContainer
                    metaJsonLdContainer
                }
                ...on CRAFT_pages_productSinglePage_Entry {
                    title
                    productSingleHero {
                        ...on CRAFT_productSingleHero_BlockType {
                            logo {
                                lqPlaceholder
                                width
                                height
                                url
                            }
                            smallLogo {
                                lqPlaceholder
                                width
                                height
                                url
                            }
                            productName
                            displayTitle
                            primaryCta {
                                type
                                text
                                url
                                target
                            }
                            secondaryCta {
                                type
                                text
                                url
                                target
                            }
                            image {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "ourProductsDesktopImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "ourProductsDesktopImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                            imageNudgedPartiallyOffScreen
                            mobileImage {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "ourProductsMobileImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "ourProductsMobileImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                            theme {
                                ...on CRAFT_themes_theme_Entry {
                                    color
                                }
                            }
                            direction
                            poweredByLogo {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "ourProductsDesktopImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "ourProductsDesktopImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                            operatingSystemLogos {
                                contents
                            }
                            operatingSystemsLabel
                        }
                    }
                    informationStripTheme {
                        ...on CRAFT_themes_theme_Entry {
                            color
                        }
                    }
                    informationStrip {
                        ...on CRAFT_informationStrip_BlockType {
                            icon {
                                url
                                contents
                            }
                            stripContent
                        }
                    }
                    introductionWithBg {
                        ...on CRAFT_introductionWithBg_BlockType {
                            introductionTitle
                            introductionContent
                            background {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "fullWidthBackgroundImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "fullWidthBackgroundImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                        }
                    }
                    productSingleStatistics {
                        ...on CRAFT_productSingleStatistics_BlockType {
                            statsTitle
                            statsContent
                            theme {
                                ...on CRAFT_themes_theme_Entry {
                                    color
                                }
                            }
                            statistics {
                                ...on CRAFT_statistics_statistic_BlockType {
                                    number
                                    suffix
                                    description
                                }
                            }
                        }
                    }
                    productFeatures {
                        ...on CRAFT_productFeatures_BlockType {
                            icon {
                                url
                                contents
                            }
                            listTitle
                            listContent
                            listLink {
                                type
                                url
                                target
                                text
                            }
                            image {
                                lqPlaceholder
                                width
                                height
                                responsiveImage(transform: "complexListImage") {
                                    width
                                    height
                                    url
                                }
                                responsiveImageWebp: responsiveImage(transform: "complexListImageWebp") {
                                    width
                                    height
                                    url
                                }
                            }
                        }
                    }
                    solutions {
                        ...on CRAFT_solutions_BlockType {
                            solutionsTitle
                            solutionsContent
                            solutions {
                                ...on CRAFT_solutions_solution_BlockType {
                                    solutionTitle
                                    tag
                                    solutionContent
                                    solutionLink {
                                        type
                                        url
                                        target
                                        text
                                    }
                                }
                            }
                        }
                    }
                    resources {
                        ...on CRAFT_resources_BlockType {
                            resourcesTitle
                            resourcesLink {
                                type
                                url
                                target
                                text
                            }
                            resourcesContent
                            otherLinks {
                                ...on CRAFT_otherLinks_link_BlockType {
                                    linkLink {
                                        type
                                        url
                                        target
                                        text
                                    }
                                }
                            }
                        }
                    }
                    callToAction {
                        ...on CRAFT_callToAction_BlockType {
                            ctaTitle
                            primaryLink {
                                type
                                url
                                target
                                text
                            }
                            secondaryLink {
                                type
                                url
                                target
                                text
                            }
                        }
                    }
                }
            }
        }
    }

`

export default productSinglePage;