import React from "react";
import styles from "./InformationStrip.module.scss";
import getColors from "../../util/getColors";
import Container from "../layout/Container";
import FadeUp from "../effects/FadeUp";

const InformationStrip = ({items, altColors, theme, productColor}) => {

    const themeColors = getColors(theme, productColor);

    const featuresElements = items && items.length ? items.map((feature,i) => {
        return (
            <FadeUp as="li" delay={`${i * 0.15}s`} disableDelayMobile={true} key={i}>
                {feature.icon && feature.icon.length ? <span className={`${styles.icon} ${altColors ? styles.iconAltColor : ""}`} dangerouslySetInnerHTML={{__html: feature.icon[0].contents}} /> : null}
                {feature.stripContent ? <p>{feature.stripContent}</p> : null}
            </FadeUp>
        );
    }) : null;

    return featuresElements ? (
        <>
            <div className={`${styles.featuresContainer}`} style={themeColors}>
                <Container>
                    <div className={styles.featuresContainer__features}>
                        <ul>
                            {featuresElements}
                        </ul>
                    </div>
                </Container>
            </div>
        </>
    ) : null;
};

export default InformationStrip;