import React from "react";
import styles from "./Statistics.module.scss";
import StatisticsItem from "./StatisticsItem";
import Section from "../layout/Section";
import Container from "../layout/Container";

const Statistics = ({items, extended, theme, productColor}) => {

    const featuresElements = items && items.length ? items.map((item,i) => {
        return (
            <StatisticsItem item={item} key={i} />
        );
    }) : null;

    return featuresElements ? (
        <Section extended={extended} className={styles.featuresContainer} theme={theme} productColor={productColor}>
            <Container className={styles.container}>
                <div className={styles.featuresContainer__features}>
                    <ul>
                        {featuresElements}
                    </ul>
                </div>
            </Container>
        </Section>
    ) : null;
};

export default Statistics;