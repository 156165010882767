import React from "react";
import styles from "./Background.module.scss";
import getColors from "../../../util/getColors";

const Background = ({children, theme}) => {
    const themeColors = getColors(theme);

    return (
        <div className={styles.background} style={themeColors}>
            {children}
        </div>
    );
};

export default Background;